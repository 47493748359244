import React from 'react';
import BaseBlockContent from '@sanity/block-content-to-react';

import Img from 'gatsby-image';
import { getFluidGatsbyImage } from 'gatsby-source-sanity';

import imageUrlBuilder from '@sanity/image-url';
import sanityConfig from '../../../sanity/sanity.json';

const builder = imageUrlBuilder(sanityConfig.api);

function urlFor(source) {
  return builder.image(source);
}

// import typography from './typography.module.css'

const Image = ({ asset, className }) => {
  const fluid = getFluidGatsbyImage(asset, { maxWidth: 960, toFormat: 'jpg' }, sanityConfig.api);

  return (
    <div className={className}>
      <Img fluid={fluid} />
    </div>
  );
}

const Download = ({ url, children }) => {
  return (
    <p className="download">
      <a className="download__link" href={url}>{children}</a>
    </p>
  );
};

const serializers = {
  types: {
    reference(props) {
      return <p>{`Reference ${props.node._ref}.`}</p>;
    },
    block(props) {
      switch (props.node.style) {
        case 'h1':
        case 'h2':
        case 'h3':
          return <h3>{props.children}</h3>;
        default:
          return <p>{props.children}</p>;
      }
    },
    section({ node }) {
      const { title, content, appearance } = node;
      const classNames = ['section', appearance || ''];

      return (
        <section className={classNames.join(' ')}>
          <div className="section__content">
            <h3>{title}</h3>
            <BlockText
              blocks={content}
            />
          </div>
        </section>
      );
    },
    image({ node }) {
      const fluid = getFluidGatsbyImage(node.asset, { maxWidth: 960, toFormat: 'jpg' }, sanityConfig.api);
      return (
        <div>
          <Img fluid={fluid} />
        </div>
      );
    },
    article({ node }) {
      const {
        title, slug, appearance, image, content, downloads
      } = node;
      const classNames = ['article', appearance || ''];
      let imageComponent;
      const downloadComponents = [];

      if (image && image._type === 'image') {
        classNames.push('has-image');
        imageComponent = (
          <Image
            className="article__image"
            asset={image.asset}
          />
        );
      }

      if (downloads && downloads.length > 0) {
        downloads.map((download) => {
          downloadComponents.push(<Download url={download.asset.url}>{download.title}</Download>)
        });
      }

      return (
        <article className={classNames.join(' ')}>
          <span id={slug.current} className="article__anchor" />
          <div className="article__content">
            <h2>{title}</h2>
            <BlockText
              blocks={content}
              className="article__content__sections"
            />
            <div className="downloads">
              {downloadComponents}
            </div>
          </div>
          {imageComponent}
        </article>
      );
    },
  },
};

const BlockText = ({ blocks, className }) => (
  <BaseBlockContent
    className={className}
    blocks={blocks}
    serializers={serializers}
  />
);

export default BlockText;
