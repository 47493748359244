import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import BlockText from '../components/block-text';

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      _rawContent(resolveReferences: { maxDepth: 5 })
    }
    articles: allSanityArticle {
      edges {
        node {
          _id
        }
      }
    }
  }
`;

const PageContent = ({ content }) => {

};

const PageTemplate = (props) => {
  const { data, errors } = props;
  const { title, _rawContent } = data.page;

  return (
    <Layout>
      <SEO title={title} />
      <BlockText blocks={_rawContent} />
    </Layout>
  );
};

export default PageTemplate;
